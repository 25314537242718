import React from "react";
import { useState } from 'react';
import { Link } from "react-router-dom";
import { Buttons } from "../components/Buttons";
import { useWindowWidth } from "../breakpoints";
import { Footer } from "../components/Footer";
import { StylePrimarySmall } from "../components/StylePrimarySmall";
import { IconCube } from "../icons/IconCube";
import { IconUser2 } from "../icons/IconUser2";
import { useAuth } from "../Auth/Auth";
import { useTranslation } from "react-i18next";
import Header from "../Header/Header.tsx";

export const WebLandingpage = ({ }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const screenWidth = useWindowWidth();
  const loggedIn = user
  return (
    <div className="web-landingpage">
      <div className="web-landingpage-v">
        <Header loggedIn={loggedIn} />
        <div className="web-home">
          <div className="header">
            <div className="div-2">
              <div className="column-2">
                <div className={screenWidth > 1440 ? "content" : "div-3"}>
                  <p className="medium-length-hero">
                    <span className="span">{t("titleBlack1")} </span>

                    <span className="text-wrapper-4">{t("titleGreen")}</span>

                    <span className="span"> {t("titleBlack2")}</span>
                  </p>

                  <p className="p">
                    {t("heroDescription")}
                  </p>
                </div>
                <Link to="/onboarding">
                  <Buttons
                    primary={true}
                    text={t("heroButton")}
                  />
                </Link>
              </div>

              <div className="content-pic">
                <div className="pic-col-left">
                  <img
                    className="placeholder-image"
                    alt=""
                    src="https://c.animaapp.com/K7QBe6oZ/img/placeholder-image@2x.png"
                  />

                  <img
                    className="placeholder-image"
                    alt=""
                    src="https://c.animaapp.com/K7QBe6oZ/img/placeholder-image-1@2x.png"
                  />

                  <img
                    className="img"
                    alt=""
                    src="https://c.animaapp.com/K7QBe6oZ/img/placeholder-image-2@2x.png"
                  />
                </div>

                <div className="pic-col-right">
                  <img
                    className="img"
                    alt=""
                    src="https://c.animaapp.com/K7QBe6oZ/img/placeholder-image-3@2x.png"
                  />

                  <img
                    className="placeholder-image"
                    alt=""
                    src="https://c.animaapp.com/K7QBe6oZ/img/placeholder-image-4@2x.png"
                  />

                  <img
                    className="placeholder-image"
                    alt=""
                    src="https://c.animaapp.com/K7QBe6oZ/img/placeholder-image-5@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="div-content">
            <p className="heading">
              <span className="text-wrapper-5">{t("benefitsTitle1")} </span>
              <span className="text-wrapper-6">{t("benefitsTitle2")}</span>
              <span className="text-wrapper-5">{t("benefitsTitle3")}</span>
            </p>

            <div className="row-wrapper">
              <div className="row">
                <div className="arg-point">
                  <div className="section-title">
                    <IconCube className="icon-relume" color="#CF5AB7" />
                    <div className="content-point">
                      <p className="heading-2">
                        {t("benefit1Title")}
                      </p>

                      <p className="text">
                        {t("benefit1Text")}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="arg-point">
                  <div className="section-title">
                    <IconCube className="icon-relume" color="#CF5AB7" />
                    <div className="content-point">
                      <p className="heading-2">
                        {t("benefit2Title")}
                      </p>

                      <p className="text">
                        {t("benefit2Text")}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="arg-point">
                  <div className="section-title">
                    <IconCube className="icon-relume" color="#CF5AB7" />
                    <div className="content-point">
                      <p className="heading-2">
                        {t("benefit3Title")}
                      </p>

                      <p className="text">
                        {t("benefit3Text")}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="arg-point">
                  <div className="section-title">
                    <IconCube className="icon-relume" color="#CF5AB7" />
                    <div className="content-point">
                      <p className="heading-2">
                        {t("benefit4Title")}
                      </p>

                      <p className="text">
                        {t("benefit4Text")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-wrapper">
            <div className="container-wrapper-box">
              <div className="div-box">
                <div className="div-box">
                  <div className="div-6">
                    <div className="tagline-wrapper">
                      <div className="tagline">{t("Match")}</div>
                    </div>

                    <div className="content-box">
                      <p className="heading-3">
                        <span className="text-wrapper-7">
                          {t("Feature1Title1")}
                        </span>

                        <span className="text-wrapper-8">
                          {t("Feature1Title2")}
                        </span>
                      </p>

                      <p className="p">
                        {t("Feature1Text")}
                      </p>
                    </div>
                  </div>
                </div>
                <Link to="/onboarding">
                  <Buttons
                    text={t("buttonFeature1")}
                  />
                </Link>
              </div>

              <img
                className="placeholder-image-half"
                alt=""
                src="https://c.animaapp.com/K7QBe6oZ/img/placeholder-image-6.png"
              />
            </div>
          </div>

          <div className="container-wrapper">
            <div className="container-wrapper-box">
              <img
                className="placeholder-image-half"
                alt=""
                src="https://c.animaapp.com/DyOG5xu3/img/placeholder-image-7@2x.png"
              />

              <div className="content-5">
                <p className="heading-3">
                  <span className="text-wrapper-8">{t("Feature2Title1")}</span>

                  <span className="text-wrapper-7">{t("Feature2Title2")}</span>

                  <span className="text-wrapper-8">{t("Feature2Title3")}</span>
                </p>

                <p className="p">
                  {t("Feature2Text")}
                </p>
              </div>


            </div>
          </div>

          <div className="layout">
            <div className="container-wrapper-box">
              <div className="div-box">
                <div className="content-box">
                  <p className="text-wrapper-9">
                    {t("socialProofTitle")}
                  </p>

                  <p className="p">
                    {t("socialProofText")}
                  </p>
                </div>

                <div className="list">
                  <div className="list-item">
                    <div className="text-wrapper-9">90%</div>
                    <p className="p">
                      {t("socialProofExp1")}
                    </p>
                  </div>

                  <div className="list-item">
                    <div className="text-wrapper-9">75%</div>
                    <p className="p">
                      {t("socialProofExp2")}
                    </p>
                  </div>
                </div>
              </div>

              <img
                className="placeholder-image-half"
                alt=""
                src="https://c.animaapp.com/DyOG5xu3/img/placeholder-image-8@2x.png"
              />
            </div>
          </div>
          <div className="container-wrapper">
            <div className="container-wrapper-box">
              <div className="div-box">
                <div className="content-box">
                  <p className="heading-3">
                    <span className="text-wrapper-8">{t("callTitleBlack1")} </span>

                    <span className="text-wrapper-7">{t("callTitleGreen")}</span>

                    <span className="text-wrapper-8"> {t("callTitleBlack2")}</span>
                  </p>

                  <p className="p">
                    {t("callDescription")}
                  </p>
                </div>
                <Link to="/onboarding">
                  <Buttons
                    text={t("callButton")}
                  />
                </Link>
              </div>

              <img
                className="placeholder-image-box"
                alt=""
                src="https://c.animaapp.com/K7QBe6oZ/img/placeholder-image-9.png"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div >
  );
};
