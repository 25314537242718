import PropTypes from "prop-types";
import React from "react";
import { IconShareIos } from "../icons/IconShareIos";
import { StatusDropdown } from "./StatusDropdown";
import { useTranslation } from "react-i18next";

export const Listing = ({ userRanking, supabase }) => {
    const StatusChanged = async (event) => {
        await supabase
            .from("UserRankings")
            .update({ status: event.target.value })
            .eq('id', userRanking.id)
    }

    const { t } = useTranslation();

    return (
        <div id={userRanking.id} className="listing">
            <div className="side">
                <div className="element">{userRanking.ranking}.</div>

                <StatusDropdown statusText={userRanking.status} onChange={StatusChanged} />
            </div>

            <div className="content">
                <p className="p">
                    {userRanking.job.title}
                </p>

                <div className="text-wrapper-2">{t("Company")}: {userRanking.job.company}</div>

                <div className="requirements-c-uni">
                    {t("Requirements")}: <br />
                    C# <br />
                    Uni Abschluss
                    <br />
                    Teamfähigkeit
                </div>
            </div>

            <a className="link-2" href={"https://de.indeed.com/viewjob?from=serp&vjs=3&jk=" + userRanking.job.jobID} target="_blank" rel="noreferrer">
                <IconShareIos className="icon-share-ios" />
                <div className="text-wrapper-3">{t("GotoPosting")}</div>
            </a>
        </div >
    );
};

Listing.propTypes = {
    userRanking: PropTypes.object,
    supabase: PropTypes.object,
};
