import React from "react";
import { useState } from "react";
import { Buttons } from "../components/Buttons";
import { NavbarOnboarding } from "../components/NavbarOnboarding";
import { OnboardingStatusBar } from "../components/OnboardingStatusBar";
import { FooterImpressum } from "../components/FooterImpressum";
import { SelectionButton } from "../components/SelectionButton";
import { useTranslation } from "react-i18next";
import "./styleOnboarding.css";

// Add Return Button func -- https://stackoverflow.com/questions/45031085/react-native-device-back-button-handling
export const Onboarding = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [workBalance, setWorkBalance] = useState(true);
    const [careerChange, setCareerChange] = useState(true);
    const [careerGoals, setCareerGoals] = useState("");
    const [cVOverview, setCVOverview] = useState("");
    const max = 6;

    const onNext = () => {
        if (page < max) {
            setPage(page + 1)
        } else {
            localStorage.setItem('workBalance', workBalance);
            localStorage.setItem('careerChange', careerChange);
            localStorage.setItem('careerGoals', careerGoals);
            localStorage.setItem('cVOverview', cVOverview);
            window.location.assign('/login')
        }
    }

    return (
        <>
            <NavbarOnboarding />
            <div className="web-onboarding">
                <div className="web-onboarding-v">

                    <div className="content-onboarding">
                        <OnboardingStatusBar State={page} Max={max} />
                        <div className="onboard-title">
                            {
                                {
                                    1: <>{t("onboardingTitle1")}</>,
                                    2: <>{t("onboardingTitle2")}</>,
                                    3: <>{t("onboardingTitle3")}</>,
                                    4: <>{t("onboardingTitle4")}</>,
                                    5: <>{t("onboardingTitle5")}</>,
                                    6: <>{t("onboardingTitle6")}</>,
                                }[page]
                            }
                        </div>

                        {
                            {
                                1: <p className="p">
                                    {t("onboardingText1")}
                                </p>,
                                2: <div className="selection-box">
                                    <div onClick={() => setWorkBalance(true)} className="hover:cursor-pointer" >
                                        <SelectionButton active={workBalance} text={t("onboardingText2a")} /></div>
                                    <div onClick={() => setWorkBalance(false)} className="hover:cursor-pointer" >
                                        <SelectionButton active={!workBalance} text={t("onboardingText2b")} /></div>
                                </div>,
                                3: <div className="selection-box">
                                    <div onClick={() => setCareerChange(true)} className="hover:cursor-pointer" >
                                        <SelectionButton active={careerChange} text={t("Yes")} />
                                    </div>
                                    <div onClick={() => setCareerChange(false)} className="hover:cursor-pointer" >
                                        <SelectionButton active={!careerChange} text={t("No")} />
                                    </div>
                                </div>,
                                4: <><p className="p">
                                    {t("onboardingText4")}
                                </p>
                                    <textarea value={careerGoals} onChange={(event) => {
                                        setCareerGoals(event.target.value);
                                    }} className="onboard-textarea" /></>,
                                5: <p className="p">
                                    {t("onboardingText5")}
                                </p>,
                                6: <>{t("onboardingText6")}<br></br>
                                    <textarea value={cVOverview} onChange={(event) => {
                                        setCVOverview(event.target.value);
                                    }} className="onboard-textarea" /></>,
                            }[page]
                        }

                        <div onClick={onNext} className="hover:cursor-pointer mt-10">
                            <Buttons
                                text={t("Continue")}
                            />
                        </div>
                    </div>
                </div>
            </div >
            <FooterImpressum
                className="onboard-footer"
            />
        </>
    );
};
