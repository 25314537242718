import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { useTranslation } from "react-i18next";

export const FooterImpressum = ({ className, divClassName = "" }) => {
    const { t } = useTranslation();
    return (
        <div className={`footer-last-line ${className}`}>
            <p className={`${divClassName}`}>© 2025 Pamina. All rights reserved.</p>

            <div className="footer-links-impressum">
                <Link to="/impressum">
                    <div className={`${divClassName}`}>{t("footerPrivacy")}</div>
                </Link>
                <Link to="/dsgvo">
                    <div className={`${divClassName}`}>{t("footerPrivacy")}</div>
                </Link>
            </div>
        </div>
    );
};
