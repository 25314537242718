import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Logo: React.FC = () => {
    const { t } = useTranslation();
  return (
    <Link to="/">
    <div className="logo-div">
      <img
        className="logo-p-only"
        alt="Logo p only"
        src="https://c.animaapp.com/DyOG5xu3/img/logo-p-only-2@2x.png"
      />

      <div className="text-primary">{t("productTitle")}</div>
    </div>
  </Link>
  );
};

export default Logo;