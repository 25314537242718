import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { StylePrimarySmall } from "../components/StylePrimarySmall";
import { IconUser2 } from "../icons/IconUser2";

const LoginButton: React.FC<{loggedIn: boolean}> = ({loggedIn }) => {
  const { t } = useTranslation();
  return (
    <>
    {loggedIn && (
      <Link to="/joblisting">
        <IconUser2 className="icon-user-2" />
      </Link>

    )}
    {!loggedIn && (
      <Link to="/joblisting">
        <StylePrimarySmall
          className="style-primary-small-true-alternate-false-icon-position-no-icon"
          divClassName="style-primary-small-instance"
          text={t("login")}
        />
      </Link>
    )}
    </>
  );
};

export default LoginButton;