import React from 'react';
import Logo from './Logo.tsx';
import LoginButton from './LoginButton.tsx';

const Header: React.FC<{loggedIn : boolean}> = ({loggedIn}) => {
  return (
    <header className="navbar">
      <div className="container">
        <Logo />
        <LoginButton  loggedIn={loggedIn} />
      </div>
    </header>
  );
};

export default Header;