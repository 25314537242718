import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import { useTranslation } from "react-i18next";

export const StatusDropdown = ({
    status,
    onChange
}) => {
    const { t } = useTranslation();
    return (<>
        <select value={status} className="frame" onChange={onChange}>
            <option value="0">{t("Status0")}</option>
            <option value="1">{t("Status1")}</option>
            <option value="2">{t("Status2")}</option>
            <option value="3">{t("Status3")}</option>
            <option value="4">{t("Status4")}</option>
            <option value="5">{t("Status5")}</option>
        </select>
    </>
    );
};

StatusDropdown.propTypes = {
    status: PropTypes.number,
    onChange: PropTypes.func,
};
